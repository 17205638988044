import packageInfo from '../../package.json';

export const environment = {
  production: true,
  apiUrl: 'https://sigma-est.izs.it/api-sigma/api',
  appVersion:  packageInfo.version,
  mailSupport: 'sigma-est.support@izs.it',
  siteKey: '6LcEnT8aAAAAAJkhOajTSPuAydMtrUJtK4iFbQMd',
  appName: 'Sigma-EST - Mapping Tool'
};
